import React, {Component, useState, useEffect, useRef } from "react";
import L from 'leaflet'
import { LatLngBounds } from 'leaflet';
import 'leaflet/dist/leaflet.css';

export default function VirtualTour() {
    const bounds = new LatLngBounds([400, 302], [-400, -302])
    const [markers, setMarkers] = useState(
        [
            {
                position: [0, 0],
                label: "example"
            }
        ]
    );

    const mapRef = useRef();

    useEffect(() => {

        const map = L.map('map').setView([0, 0], 1);

        L.imageOverlay(`/img/virtual-tour/virtual-tour.png`, bounds)
            .addTo(map);

        L.polygon([
            [44.08758502824518, 5.625],
            [49.38237278700955, 35.85937500000001],
            [47.989921667414194, 62.57812500000001],
            [27.059125784374068, 59.76562500000001],
            [3.5134210456400448, 61.17187500000001],
            [-9.79567758282973, 42.18750000000001],
            [-7.01366792756663, 11.953125000000002],
            [-10.487811882056683, -18.281250000000004],
            [1.4061088354351594, -38.67187500000001],
            [8.407168163601076, -50.62500000000001],
            [21.289374355860424, -47.81250000000001],
            [29.53522956294847, -30.234375000000004],
            [36.59788913307022, -6.328125000000001]
        ]).addTo(map)
            .bindPopup(
                `
                    <h2 class="h5">Open play / event grounds</h2>
                    <p>This area enables us to hold Open Days with a (small) cricket pitch sized circle offering free rein for a wide range of activities.</p>
                `
        );
            
        L.polygon([
            [-81.92318632602199, - 283.35937500000006],
            [-55.7765730186677, -225.70312500000003],
            [-63.860035895395306, -182.10937500000003],
            [-71.74643171904148, -153.28125],
            [-69.162557908105, -96.32812500000001],
            [-76.01609366420996, -110.39062500000001],
            [-78.76779175784321, -128.67187500000003],
            [-80.53207112232732, -130.78125000000003],
            [-82.58610635020881, -196.87500000000003],
            [-84.9901001802348, -184.921875],
            [-84.92832092949963, -215.85937500000003],
            [-81.92318632602199, -281.95312500000006]
        ]).addTo(map)
            .bindPopup(
                `
                    <h2 class="h5">Arboretum and wildflower wood</h2>
                    <p>This area of the gardens is subject to some winter flooding and so we decided to introduce a series of trees  including oaks alders, ash, willows bird cherries laurels silver birches  and beeches.These have been very successful and have been supplemented by  bamboos, phototynias, magnolia, hydrangea, kerria japonica, gunnera lilies and a raft of  recent wildflower additions including  English  bluebells, snowdrops, wild garlic, fritillarias and many others.</p>
                `
            );

        L.polygon([
            [-22.59372606392931, -137.10937500000003],
            [-17.978733095556155, -120.93750000000001],
            [-21.289374355860424, -105.46875000000001],
            [-28.30438068296277, -89.296875],
            [-36.03133177633188, -87.89062500000001],
            [-40.979898069620134, -98.43750000000001],
            [-43.580390855607845, -120.93750000000001],
            [-42.55308028895581, -136.40625000000003],
            [-36.03133177633188, -144.84375000000003],
            [-27.68352808378776, -140.62500000000003]
        ]).addTo(map)
            .bindPopup(
                `
                    <h2 class="h5">Pond</h2>
                    <p>The pond originally filled up in winter through natural drainage.In recent years dry winters and summers have reduced the pond level and it is suspected that builing work north east of the pond has interfered with the previous level of natural drainage.Nevertheless a wide range of pond plants have "adopted" the area.</p>
                `
            );


        L.polygon([
            [-58.44773280389084, -190.54687500000003],
            [-61.60639637138628, -173.67187500000003],
            [-65.94647177615738, -169.45312500000003],
            [-69.90011762668541, -156.09375000000003],
            [-69.90011762668541, -139.92187500000003],
            [-68.13885164925573, -108.28125000000001],
            [-68.39918004344189, -89.296875],
            [-61.93895042666062, -79.45312500000001],
            [-58.07787626787517, -87.18750000000001],
            [-54.1624339680678, -96.32812500000001],
            [-51.6180165487737, -111.09375000000001],
            [-48.92249926375824, -130.07812500000003],
            [-47.040182144806664, -140.62500000000003],
            [-38.82259097617711, -146.25000000000003],
            [-28.92163128242129, -146.95312500000003],
            [-19.31114335506464, -146.25000000000003],
            [-18.646245142670608, -156.79687500000003],
            [-32.546813173515154, -175.78125000000003],
            [-43.580390855607845, -182.81250000000003],
            [-54.1624339680678, -188.4375],
        ]).addTo(map)
            .bindPopup(
                `
                    <h2 class="h5">Bog garden and rockery</h2>
                    <p>The bog garden and rockery plants include Phormias, Pampas Grass, Gunnera  wild Primroses, daisies, lilies and others.The area has developed a very  "alien" feel to it in keeping with the idea of introducing a very naturalised and rich microworld!</p>
                `
            );

        L.polygon([
            [-56.559482483762245, -68.90625000000001],
            [-61.27023279000062, -75.23437500000001],
            [-65.94647177615738, -80.85937500000001],
            [-68.65655498475736, -87.18750000000001],
            [-71.96538769913128, -97.03125000000001],
            [-76.9999351181161, -107.57812500000001],
            [-78.9039293885709, -123.75000000000001],
            [-80.41570744462179, -127.26562500000001],
            [-80.53207112232732, -110.39062500000001],
            [-80.297927149974, -95.62500000000001],
            [-79.56054626376365, -85.78125],
            [-78.06198918665974, -73.82812500000001],
            [-76.9999351181161, -64.68750000000001],
            [-74.01954331150226, -59.76562500000001],
            [-69.41124235697255, -55.54687500000001],
            [-64.77412531292872, -51.32812500000001],
            [-60.58696734225869, -51.32812500000001],
            [-57.32652122521708, -57.65625000000001],
            [-55.7765730186677, -66.09375000000001],
            [-58.813741715707806, -73.82812500000001]
        ]).addTo(map)
            .bindPopup(

                `
                    <h2 class="h5">Picnic and play area</h2>
                    <p>Picnic tables have been provided in a general grassed play area for family activities away from areas of potential damage to plants.</p>
                `
            );
       
        L.polygon([
            [46.55886030311719, 73.82812500000001],
            [16.636191878397664, 84.37500000000001],
            [20.632784250388028, 119.53125000000001],
            [8.407168163601076, 130.07812500000003],
            [25.799891182088334, 190.54687500000003],
            [65.07213008560697, 163.82812500000003],
            [61.270232790000634, 144.14062500000003],
            [57.326521225217064, 137.81250000000003],
            [46.55886030311719, 76.640625]
        ]).addTo(map)
            .bindPopup(
                `
                    <h2 class="h5">Allotment Plots</h2>
                    <p>There are currently some 20  plots of varying sizes.The Garden concept means that there are no sheds or other significant constructions but allotment holders do have a ready supply of free compost and water.</p>
                `
            );


        L.polygon([
            [-10.833305983642491, 72.42187500000001],
            [-52.052490476001, 71.71875000000001],
            [-52.908902047770255, 108.28125000000001],
            [-10.833305983642491, 106.87500000000001],
        ]).addTo(map)
            .bindPopup(
                `
                    <h2 class="h5">Allotment Plots</h2>
                    <p>There are currently some 20  plots of varying sizes.The Garden concept means that there are no sheds or other significant constructions but allotment holders do have a ready supply of free compost and water.</p>
                `
        );

        L.polygon([
            [-38.82259097617711, -33.04687500000001],
            [-26.43122806450644, -0.703125],
            [-33.7243396617476, 7.734375000000001],
            [-22.59372606392931, 37.96875000000001],
            [-50.28933925329178, 61.17187500000001],
            [-57.70414723434192, 32.34375000000001],
            [-62.26792262941758, 34.45312500000001],
            [-68.39918004344189, -0.703125],
        ]).addTo(map)
            .bindPopup(
                `
                    <h2 class="h5">Allotment Plots</h2>
                    <p>There are currently some 20  plots of varying sizes.The Garden concept means that there are no sheds or other significant constructions but allotment holders do have a ready supply of free compost and water.</p>
                `
            );

        L.polygon([
            [2.1088986592431382, 130.07812500000003],
            [9.795677582829743, 167.34375000000003],
            [13.923403897723347, 198.28125],
            [1.4061088354351594, 217.96875],
            [-21.289374355860424, 222.18750000000003],
            [-43.06888777416962, 215.15625000000003],
            [-52.48278022207821, 201.79687500000003],
            [-56.944974180851595, 181.40625],
            [-60.23981116999893, 163.12500000000003],
            [-60.58696734225869, 143.43750000000003],
            [-53.748710796898976, 139.21875000000003],
            [-39.368279149160124, 141.32812500000003],
            [-23.88583769986199, 139.92187500000003],
            [1.4061088354351594, 131.48437500000003],
        ]).addTo(map)
            .bindPopup(
                `
                    <h2 class="h5">Wild Flower Meadow</h2>
                    <p>This are and some of the border have been left to "find their own way".However over 100 shrubs and small trees have been planted including Rowan trees and Dogwood shrubs whilst the borders offer plenty of blackberry bushes for seasonal fruit picking!  In addition this and other areas of the gardens have bird nests installed.</p>
                `
            );

        L.polygon([
            [4.214943141390651, -128.67187500000003],
            [13.239945499286312, -117.42187500000001],
            [9.795677582829743, -101.95312500000001],
            [0, -71.71875000000001],
            [-19.973348786110602, -33.75000000000001],
            [-40.4469470596005, -52.03125],
            [-1.4061088354351594, -123.75000000000001],
        ]).addTo(map)
            .bindPopup(
                `
                    <h2 class="h5">Orchard</h2>
                    <p>The Orchard has suffered losses through vandalism and natural events but includes apple,  pear, greengage, plum and damson trees.</p>
                `
            )

    });


    return (
        <div className="container mt-3 fixed-height">
            <div id="map" ref={mapRef} style={{ height: '800px' }} />
        </div>
        );
}
