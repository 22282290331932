import React, {Component} from 'react';

export default function InMemory() {
	return (
		<div className="container mt-3 fixed-height">
			<div className={`row`}>
				<h1>Geoffrey Husband</h1>
				<div className={`col col-sm-12 col-md-12 col-lg-4`}>
					<img src="/img/in-memory/geoffrey-husband.jpg" alt="" width="300" height="auto"/>
				</div>
				<div className={`col col-sm-12 col-md-12 col-lg-8`}>
					<h3>Chairman 2002 to 2013</h3>
					<p>Geoff passed away , suddenly , on June 1st 2023 . He was our first and much loved Chairman , popular with everyone whilst carrying out a role he was "pressed ganged" to do.!</p>
					<p>Geoff was a long serving member of the RAF before he retired. A native of Sheffield he loved nature , poetry and good banter ! And Sunderland FC !!</p>
					<p>His reports for this period can still be read , under Chairman's Reports , and these well represent his engagement with MMGA (MMAA as was) and its members. The Laburnum Walk and the Willow Arch are two of his "inspirations".</p>
					<p>He got us off to a good start! Lets hope we can do him justice.</p>
				</div>
				<hr className={`mt-3`}/>
				<h1>Derek Cowley</h1>
				<div className={`col col-sm-12 col-md-12 col-lg-4`}>
					<img src="/img/in-memory/derek-cowley.jpeg" alt="" width="300" height="264"/>
				</div>
				<div className={`col col-sm-12 col-md-12 col-lg-8`}>
					<p>MMAA Are sad to announce the loss of Derek Cowley, one of the founder members of the Association who died unexpectedly on the 2nd of October 2007. Derek was an integral part of the project from the start, and his tireless efforts have made the gardens what they are today. Derek’s input to the project will be irreplaceable, and we are all proud to have known and worked with him. Our thoughts are with his wife Yvonne and his family at this difficult time.</p>
				</div>
				<hr className={`mt-3`}/>
				<h1>Ron Goodwin</h1>
				<div className={`col col-sm-12 col-md-12 col-lg-4`}>
					<img src="/img/in-memory/ron-goodwin.jpeg" alt="" width="300" height="265"/>
				</div>
				<div className={`col col-sm-12 col-md-12 col-lg-8`}>
					<p>Ron was a huge supporter of the community garden and his energy, hard work and input has kept the project on track and helped us all meet the challenges it has created.</p>
					<p>This is the last picture we have of Ron on site just a few weeks before his passing.</p>
					<p>We will miss his smile, happy conversation, hard work, guidance and friendship.</p>
					<p><span>God Bless Ron a True Gentleman!</span></p>
				</div>
			</div>
		</div>
		);
}