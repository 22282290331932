import React, { Component, useState } from "react";
import dataFile from '../data/projects_overview.json';
import { Routes, Route, Link, Outlet, useLocation, useParams } from "react-router-dom";

export default function Projects() {
const [data, setData] = useState(dataFile);
let location = useLocation();
let urlParams = useParams();

    function handleProjects() {
        if (data) {
        return (
            <>
                {Object.keys(dataFile).map(key => {
                    const data = dataFile[key];
                    const additionalInfo = data.additional_information;
                    let link;
                    if(additionalInfo){
                        link = <Link to={key} className="project-link">See more...</Link>;
                    }
                    return (
                        <div key={key} className={`row`}>
                            <h2 className="h5">{data.title}</h2>
                            <div className={`col col-sm-12 col-md-12 col-lg-4`}>
                                <img src={data.img_src} alt="" width="300" height="auto" />
                            </div>
                            <div className={`col col-sm-12 col-md-12 col-lg-8`}>
                                <p>{data.description}</p>
                                    {link}
                                </div>
                            <hr className={`mt-3`} />
                        </div>
                        );
                })}
            </>
            );  
            }  
    }

    return (
        <div className="container mt-3 fixed-height">
                {handleProjects()} 
        </div>
    );
}