import React, { Component } from "react";
import { Navbar, Nav } from 'react-bootstrap';
import {
    HashRouter,
    Routes,
    Route,
    Link
  } from "react-router-dom";
import { useMediaQuery } from 'usehooks-ts';


export default function FooterNavigation() {
const matches = useMediaQuery('(min-width: 992px)', 'Less than 992px wide', 'More than 992px wide');

function handleFooterLinks () {
    if (matches) {
        return (
            <ul className="nav justify-content-center border-bottom pb-3 mb-3">
              <li className="nav-item"><Link to="/constitution" className="nav-link px-2 text-muted"><strong>Constitution</strong></Link></li>
              <li className="nav-item"><Link to="/friends-of-mmga" className="nav-link px-2 text-muted"><strong>Friends of Mastin Moor Gardens and Allotments</strong></Link></li>
              <li className="nav-item"><Link to="/in-memory" className="nav-link px-2 text-muted"><strong>In memory</strong></Link></li>
              <li className="nav-item"><Link to="/sponsors-and-links" className="nav-link px-2 text-muted"><strong>Sponsors and links</strong></Link></li>
              <li className="nav-item"><Link to="/virtual-tour" className="nav-link px-2 text-muted"><strong>Virtual tour</strong></Link></li>
            </ul>
            );
    }
}
        return (
             <footer className="py-3 my-4 footer-styling">
                {handleFooterLinks()}
                <p className="text-center text-muted">Registered with the Charities Commission registration number 1172292</p>
                <p className="text-center text-muted">© {(new Date().getFullYear())} Mastin Moor Gardens and Allotments</p>
            </footer>
            );
    }
