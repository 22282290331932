// import logo from './logo.svg';
import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import './pdf_viewer.css';
import Navigation from './components/Navigation.jsx';
import HeaderImg from './components/HeaderImage.jsx';
import FooterNavigation from './components/FooterNavigation.jsx';
import {
    HashRouter,
    Routes,
    Route, 
    Link,
    Outlet
  } from "react-router-dom";
import Home from "./components/Home.jsx";
import Membership from "./components/Membership.jsx";
import RootsAndBranchDerbyshire from "./components/RootsAndBranchDerbyshire.jsx";
import ChairmansReport from "./components/ChairmansReport.jsx";
import Contact from "./components/Contact.jsx";
import Projects from "./components/Projects.jsx";
import News from "./components/News.jsx";
import Constitution from './components/Constitution.jsx';
import FriendsOfMMGA from './components/FriendsOfMMGA.jsx';
import SponsorsAndLinks from './components/SponsorsAndLinks.jsx';
import InMemory from './components/InMemory.jsx';
import ProjectExtended from './components/ProjectExtended.jsx';
import ProjectsOverview from './components/ProjectsOverview.jsx';
import Gallery from './components/Gallery.jsx';
import VirtualTour from './components/VirtualTour.jsx';

function App() {
  return (
    <div className="App">
      <HashRouter>
        <HeaderImg /> 
        <Navigation />
        <Routes>
            <Route index element={<Home />}>Home</Route>
            <Route path="/contact-us" element={<Contact />}>Contact us</Route>
            <Route path="/gallery" element={<Gallery />}>Gallery</Route>
            <Route path="/membership" element={<Membership />}>Membership</Route>
            <Route path="/news" element={<News />}>News</Route>
            <Route path="/projects" element={<ProjectsOverview />}>
            <Route index element={<Projects />}/>
            <Route path=":projectId" element={<ProjectExtended />}/>
            </Route>
            <Route path="/roots-and-branch-derbyshire" element={<RootsAndBranchDerbyshire />}>Roots and Branch Derbyshire</Route>
            <Route path="/trustees-chairmans-report" element={<ChairmansReport />}>Trustees/Chairman’s report</Route>
            <Route path="/constitution" element={<Constitution />}>Constitution</Route>
            <Route path="/friends-of-mmga" element={<FriendsOfMMGA />}>Friends of MMGA</Route>
            <Route path="/in-memory" element={<InMemory />}>In memory</Route>
            <Route path="/sponsors-and-links" element={<SponsorsAndLinks />}> Sponsors and links</Route>
            <Route path="/virtual-tour" element={<VirtualTour />}>Virtual tour</Route>
        </Routes>
        <FooterNavigation />
      </HashRouter> 
    </div>
  );
}

export default App;
