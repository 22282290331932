import React from "react";
import Image from 'react-bootstrap/Image';
import HeaderImage from '../img/header-img.png';

export default function FriendsOfMMGA() {
   
    return (
    	 <div className="container mt-3 fixed-height">
    		<h1>Friends of Mastin Moor Gardens and Allotments</h1>
			<h2>May 2018</h2>
			<p>I am pleased to announce that the Trustees have agreed to the introduction of a new class of member in Mastin Moor Gardens and Allotments CIO.</p>
			<p>To this end you will find attached a full explanation of how (easily!) you can become a supporter of our charity.</p>
			<p>An organisation such as ours aims to support allotment holders and also the full community.&nbsp; You don’t need an allotment to enjoy the gardens and open spaces and you don’t have to pay a thing to do so. You will appreciate I hope that all the “experts” are now supporting what some of us have known for years …that the enjoyment of fresh air gardens and wildlife are positive to both our physical and mental health…indeed some are now arguing they are vital.</p>
			<p>Becoming a member will allow you to support our efforts financially (maintaining the gardens is not cost free!)&nbsp; and offer the chance of involvement and friendship. In addition we have just received notice that the Woodland Trust will be providing us with over 400 trees in late 2018.&nbsp; For a small additional donation we will allow you to choose one of these trees in your (anyone else’s!) name.</p>
			<p>And we can also provide the opportunity to put a memorial tree, bench or seat in the gardens, something a number of friends have already done.</p>
			<p>We look forward to hearing from you.</p>
			<p>Keith Hodgson</p>
			<p>Chairman</p>
		</div>
    );
}