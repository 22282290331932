import React, { Component, useState, useEffect } from "react";
import { Navbar, Nav, Container, Offcanvas } from 'react-bootstrap';
import { Link } from "react-router-dom";
import { useMediaQuery, useEventListener } from 'usehooks-ts';

export default function Navigation() {
const [expanded, setExpanded] = useState(false);

  const matches = useMediaQuery('(min-width: 992px)');

  var sectionStyle = {
     height: "180px",
        display: "flex",
        alignItems: "center",
        top: 0
    };

function handleNavigation () {
    if (matches) {
        return (
            <Navbar bg="light" variant="light" className="justify-content-center">
                <Nav className="container navigation-height">
                    <Link to="/">Home</Link>
                    <Link to="/contact-us">Contact us</Link>
                    <Link to="/gallery">Gallery</Link>
                    <Link to="/membership">Membership</Link>
                    <Link to="/news">News</Link>
                    <Link to="/projects">Projects</Link>
                    <Link to="/roots-and-branch-derbyshire">Roots and Branch Derbyshire</Link>
                    <Link to="/trustees-chairmans-report"> Trustees/Chairman’s report</Link>
                </Nav>
            </Navbar>
            );
        } else {
            return (
                <Navbar className={`position-absolute fixed-top w-100`} style={sectionStyle} expand={false} expanded={expanded}>
                  <Container fluid>
                    <Navbar.Brand href="/"></Navbar.Brand>
                    <Navbar.Toggle className={`light-toggle`} aria-controls="offcanvasNavbar" onClick={() => setExpanded(expanded ? false : "expanded")}/>
                    <Navbar.Offcanvas
                      id="offcanvasNavbar"
                      aria-labelledby="offcanvasNavbarLabel"
                      placement="end">
                      <Offcanvas.Header closeButton onClick={() => setExpanded(expanded ? false : "expanded")}>
                        <Offcanvas.Title id="offcanvasNavbarLabel">Menu</Offcanvas.Title>
                      </Offcanvas.Header>
                      <Offcanvas.Body>
                        <Nav className="justify-content-end flex-grow-1 pe-3">
                            <Link className={`p-3`} to="/" onClick={() => setExpanded(0)}>Home</Link>
                            <Link className={`p-3`} to="/contact-us" onClick={() => setExpanded(0)}>Contact us</Link>
                            <Link className={`p-3`} to="/gallery" onClick={() => setExpanded(0)}>Gallery</Link>
                            <Link className={`p-3`} to="/membership" onClick={() => setExpanded(0)}>Membership</Link>
                            <Link className={`p-3`} to="/news" onClick={() => setExpanded(0)}>News</Link>
                            <Link className={`p-3`} to="/projects" onClick={() => setExpanded(0)}>Projects</Link>
                            <Link className={`p-3`} to="/roots-and-branch-derbyshire" onClick={() => setExpanded(0)}>Roots and Branch Derbyshire</Link>
                            <Link className={`p-3`} to="/trustees-chairmans-report" onClick={() => setExpanded(0)}> Trustees/Chairman’s report</Link>
                            <Link className={`p-3`} to="/constitution" onClick={() => setExpanded(0)}>Constitution</Link>
                            <Link className={`p-3`} to="/friends-of-mmga" onClick={() => setExpanded(0)}>Friends of Mastin Moor Gardens and Allotments</Link>
                            <Link className={`p-3`} to="/in-memory" onClick={() => setExpanded(0)}>In memory</Link>
                            <Link className={`p-3`} to="/sponsors-and-links" onClick={() => setExpanded(0)}>Sponsors and links</Link>
                            <Link className={`p-3`} to="/virtual-tour" onClick={() => setExpanded(0)}>Virtual Tour</Link>
                        </Nav>
                      </Offcanvas.Body>
                    </Navbar.Offcanvas>
                  </Container>
                </Navbar>
            );
        }
    }

    return (
        <>
            {handleNavigation()}
        </>
    );
}