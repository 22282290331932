import React from "react";

export default function SponsorsAndLinks() {
   
    return (
		<div className="container mt-3 fixed-height">
			<h2 className={`mb-3`}>Tesco’s Bags of Help</h2>
			<p>
				<img src="/img/sponsors-and-links/tesco.jpeg" alt="" width="300" height="120"/>
			</p>
			<hr/>
			<h2 className={`mb-3`}>Big Lottery Fund</h2>
			<p>
				<img src="/img/sponsors-and-links/big_lottery_fund.png" alt="" width="225" height="225" />
			</p>
			<hr/>
			<h2 className={`mb-3`}>Groundwork Creswell</h2>
			<p>
				<a href="http://www.groundwork.org.uk/">
					<img src="/img/sponsors-and-links/groundwork_creswell.gif" alt="" width="228" height="276" />
				</a>
			</p>
			<hr/>
			<h2 className={`mb-3`}>Staveley Neighbourhood Management</h2>
			<p>
				<a href="http://staveley-neighbourhood.info/">
					<img src="/img/sponsors-and-links/stavely_neighbourhood_management.jpeg" alt="" width="300" height="105" />
				</a>
			</p>
			<hr/>
			<h2 className={`mb-3`}>Neighbourhood Renewal Unit</h2>
			<p>
				<a href="http://staveley-neighbourhood.info/">
					<img src="/img/sponsors-and-links/neighbourhood_renewal_unit.jpeg" alt="" width="150" height="135" />
				</a>
			</p>
			<hr/>
			<h2 className={`mb-3`}>Barclays sitesavers</h2>
			<p>
				<a href="http://www.barclays-sitesavers.org.uk/index.html">
					<img src="/img/sponsors-and-links/barclays_site_savers.gif" alt="" width="299" height="51" />
				</a>
			</p>
			<hr/>
			<h2 className={`mb-3`}>Chesterfield Borough Council</h2>
			<p>
				<a href="http://www.chesterfieldbc.gov.uk">
					<img src="/img/sponsors-and-links/chesterfield_council.gif" alt="" width="244" height="151" />
				</a>
			</p>
			<hr/>
			<h2 className={`mb-3`}>Community Payback</h2>
			<p>
				<a href="https://www.gov.uk/government/organisations/national-offender-management-service/about-our-services">
					<img src="/img/sponsors-and-links/community_payback.jpeg" alt="" width="227" height="187" />
				</a>
			</p>
			<hr/>
			<h2 className={`mb-3`}>Derbyshire Council</h2>
			<p>
				<a href="http://www.derbyshire.gov.uk/">
					<img src="/img/sponsors-and-links/derbyshire_council.gif" alt="" width="237" height="81" />
				</a>
			</p>
			<hr/>
			<h2 className={`mb-3`}>Barnsley BS</h2>
			<p>
				<a href="http://www.barnsley-bs.co.uk/welcome.htm">
					<img src="/img/sponsors-and-links/barnsley_building_society.gif" alt="" width="300" height="71" />
				</a>
			</p>
		</div>
    );
}