import React, { Component, useState } from "react";
import { Outlet } from "react-router-dom";

export default function ProjectExtended() {

   return (
        <div className="container mt-3 fixed-height">
            <Outlet />
        </div>
    );
}