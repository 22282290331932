import React from "react";

export default function RootsAndBranchDerbyshire() {
    return (
        <div className="container mt-3 fixed-height">
            <h1>Root and Branch Derbyshire</h1>
            <p>For several years Mastin Moor Gardens have provided faciilities for a local group, “Root and Branch Derbyshire ” to get together and “self help” people with mental health difficulties.</p>
            <p>This has provided a mutual benefit with the Gardens offering both a chance to get together and to experience the relaxing and stimulating gardening and allotment activities. We hope to continue this relationship in the years ahead in the belief that we can provide a form of support not readily available elsewhere, especially in the National Health and Social services.</p>
            <p>Root and Branch has its own website. Please go to <a className="project-link" href="http://www.rootandbranchderbyshire.org/" target="_blank" rel="noopener">www.rootandbranchderbyshire.org</a>.</p>
        </div>
    );
}