import React from "react";
import { Carousel } from 'react-bootstrap';
import one from '../img/carousel/new-1.jpg';
import two from '../img/carousel/new-2.jpg';
import three from '../img/carousel/new-3.jpg';
import four from '../img/carousel/new-4.jpg';
import five from '../img/carousel/new-5.jpg';
import six from '../img/carousel/new-6.jpeg';
// import one from '../img/carousel/1.jpg';
// import two from '../img/carousel/2.jpg';
// import three from '../img/carousel/3.jpg';
// import four from '../img/carousel/4.jpg';
// import five from '../img/carousel/5.jpg';
// import six from '../img/carousel/6.jpg';
// import seven from '../img/carousel/7.jpg';
// import eight from '../img/carousel/8.jpg';
// import nine from '../img/carousel/9.jpg';
// import ten from '../img/carousel/10.jpg';
// import eleven from '../img/carousel/11.jpg';
// import twelve from '../img/carousel/12.jpg';
// import thirteen from '../img/carousel/13.jpg';

export default function HomeImages() {
    return (
        <Carousel height="250" className="text-center">
                <Carousel.Item>
                    <img
                        className="img-carousel"
                        src={one}
                        alt="First slide"
                    />
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="img-carousel"
                        src={two}
                        alt="First slide"
                    />
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="img-carousel"
                        src={three}
                        alt="First slide"
                    />
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="img-carousel"
                        src={four}
                        alt="First slide"
                    />
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="img-carousel"
                        src={five}
                        alt="First slide"
                    />
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="img-carousel"
                        src={six}
                        alt="First slide"
                    />
                </Carousel.Item>
                {/* <Carousel.Item> */}
                {/*     <img */}
                {/*         className="img-carousel" */}
                {/*         src={seven} */}
                {/*         alt="First slide" */}
                {/*     /> */}
                {/* </Carousel.Item> */}
                {/* <Carousel.Item> */}
                {/*     <img */}
                {/*         className="img-carousel" */}
                {/*         src={eight} */}
                {/*         alt="First slide" */}
                {/*     /> */}
                {/* </Carousel.Item> */}
                {/* <Carousel.Item> */}
                {/*     <img */}
                {/*         className="img-carousel" */}
                {/*         src={nine} */}
                {/*         alt="First slide" */}
                {/*     /> */}
                {/* </Carousel.Item> */}
                {/* <Carousel.Item> */}
                {/*     <img */}
                {/*         className="img-carousel" */}
                {/*         src={ten} */}
                {/*         alt="First slide" */}
                {/*     /> */}
                {/* </Carousel.Item> */}
                {/* <Carousel.Item> */}
                {/*     <img */}
                {/*         className="img-carousel" */}
                {/*         src={eleven} */}
                {/*         alt="First slide" */}
                {/*     /> */}
                {/* </Carousel.Item> */}
                {/* <Carousel.Item> */}
                {/*     <img */}
                {/*         className="img-carousel" */}
                {/*         src={twelve} */}
                {/*         alt="First slide" */}
                {/*     /> */}
                {/* </Carousel.Item> */}
                {/* <Carousel.Item> */}
                {/*     <img */}
                {/*         className="img-carousel" */}
                {/*         src={thirteen} */}
                {/*         alt="First slide" */}
                {/*     /> */}
                {/* </Carousel.Item> */}
            </Carousel>
    );
}