import React from "react";
import HomeImages from './HomeImages.jsx';

export default function Home() {
    return (
        <div className="container mt-3 fixed-height">
            <p><strong>National Gardens scheme weekend July 29th and 30th 2023. For details please go to our news tab - NGS weekend July 29th and 30th, select the "down arrow" and read all about it!</strong></p>
            <p>A site dedicated to the revival and renovation of our derelict allotment site into a new community garden for the use of local people. Providing a pleasant habitat for people and wildlife alike. And an environment to grow organic fruit and vegetables for locals to consume.</p>
            <p>Mastin Moor is a former mining village on the A619 near Staveley in Derbyshire. In 2002 a derelict site was “resurrected” and a gardens with open allotments was created through the work of a handful of dedicated volunteers. The Mastin Moor Allotments Association has, in 2017 , progressed to the point at which it has been “morphed” into a Charitable Incorporated Organisation, a charity registered with the Charities Commission.</p>
            <p>The Gardens and Allotments provides open public space for visitors to enjoy with well over one mile of paths, an arboretum, wildlife, flowerbeds, pond, bog garden picnic area as well as the allotments.</p>
            <p>You are more than welcome to visit us.</p>

            <p>
                <strong>
                    <a href="/download-docs/mmaa-short-story.doc">Click here to see more about MMAA's history...</a>
                </strong>
            </p>
            <h2 className="h4">Where are we?</h2>
            <p>We are located just below the main crossroads (A691/B6419)  in Mastin Moor.  There is a gated access next to the layby on Bolsover Road (B6419) and a pedestrian access some 100 yards below the crossroads heading towards Staveley.</p>
            <HomeImages />
        </div>
    );
}