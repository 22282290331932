import React from "react";

export default function Membership() {
    return (
        <div className="container mt-3 fixed-height">
            <h1>Membership Details</h1>
            <p>We have two main types of member, Allotment Holders, and Friends of MMGA
                “Friends“ are invited to contribute £7 in annual donations to support our efforts.</p>
            <p>Allotment Growers will have a yearly rental for the use of their plot, At present this annual fee is is detailed below:</p>
            <p><strong>Large Plot – £30 per year</strong></p>
            <p><strong>Half Plot – £15 per year</strong></p>
            <p><strong>Small Plot – £7.50 per year</strong></p>
            <p>This entitles the allotment holder to cultivate his plot in line with the constitution and rules of the association. They and Friends of the MMGA are encouraged to contribute to the running of the site. Any other support i.e. donations of plants, help, or money, is always welcome.</p>
            <p>In all cases a completed membership form must be provided, and on payment of fees. These forms are available from the secretary or may be downloaded and printed in pdf format from this site on the links below.</p>
            <p>Please note the Trustees of MMGA reserve the right, if allotment holders are in breach of the constitution/aims/objectives of the site, to withdraw the use of a plot.</p>
        </div>

    );
}