import React, { Component, useState } from "react";
import galleryData from '../data/gallery.json';

export default function Gallery() {
    const [data, setData] = useState(galleryData);

    function handleGallery() {
        if (data) {
            return (
                <div className="container mt-3 fixed-height">
                    {Object.keys(data).map(key => {
                        const galleryObject = data[key];
                        let title;
                        if (galleryObject.title) {
                            title = <h1>{galleryObject.title}</h1>;
                        }
                        const galleryImages = galleryObject.images;
                        return (
                            <div className="row" key={key}>
                                {title}
                                {galleryImages.map(value => {

                                    let imgText;
                                    if (value.img_text) {
                                        imgText = <p className={`text-center pt-2`}>{value.img_text}</p>
                                    }

                                    return (
                                        <div key={value.img_src} className={`col-sm-12 col-md-4 col-lg-3 p-1 m-auto`}>
                                            <img className={`img-fluid`} src={`${galleryObject.image_dir}` + `${value.img_src}`} alt={key} />
                                            {imgText}
                                        </div>
                                    );
                                })}
                                <hr className={`mt-3`} />
                            </div>
                        );

                    })}
                </div>
            );
        }
    }

    return (
        <>
            {handleGallery()}
        </>
    );

}