import React from "react";

export default function Membership() {
  return (
    <div className="container mt-3 fixed-height">
      <h1>Chairman's Report</h1>
      <div className="mt-3">
        <h2>TREASURERS REPORT</h2>
        <h3>REPORT AND ACCOUNTS FOR PERIOD ENDING MARCH 30th 2024</h3>

        <p>
          Activity in 2023/2024 recovered from the Covid 19 pandemic as “normal
          life” was resumed .
        </p>

        <p>
          The year was a successful one as we continued to progress the
          Chatsworth project with the Devonshire Property group (DPG) and
          successfully ran the National Gardens Scheme weekend during 2023..
        </p>

        <p>
          We are particularly indebted to DPG who, at their full expense,
          purchased circa 82 sq metres of land from Staveley Town Council (STC)
          to assist in the road visibility (associated with their housing
          development), replaced the main Bolsover Road gate with a new Larch 4
          metre wide gate and fencing , removed approx 20 metres of the hedge
          and installed circa 120 saplings for the moved replacement hedge and
          laid new access tarmac in the entrance giving a much improved entrance
          access and much improved access and road safety.
        </p>

        <p>
          The allotments remained fully let and the site continued to develop
          “naturally” at a fast pace . Efforts by several key people provided
          new and very successful flower beds around the site ..
        </p>

        <p>
          Allotment fees were held at £30 for a full plot and a total of £382
          was collected.
        </p>

        <p>
          We were successful in raising funds from several grants. Derbyshire
          County Council , courtesy of Councillor Hayes, provided £869 towards a
          new Gala Tent to help our National Gardens Scheme weekend, Derbyshire
          Voluntary Action provided £1550 towards our activities , Hartington
          Reclamation provided £1000 for our equipment needs and Co-op Midlands
          provided £524 as part of a contribution over the year 2024. The Duke
          of Devonshire Charitable Trust provided £2000 towards the proposed
          Container Cafe.
        </p>

        <p>
          In Spring 2023 we removed the old Monet bridge (after 17 years of
          service) and Bolsover Woodland Enterprise provided and installed a
          substantial replacement “Monet bridge” at a total cost of £2190.
          Bolsover also provided a replacement seat for the Derek Cowley
          memorial.
        </p>

        <p>
          The National Garden Scheme (NGS) weekend was held over July 29th and
          30th . Despite cool weather the event was a success and raised £1060
          which allowed net proceeds of £689 to be paid to NGS in aid of the
          Macmillan Cancer Trust. Graeme Challands provided key support to this
          task.
        </p>

        <p>
          Progress was made on the proposed Container /Cafe . By January 2024
          planning approval had been obtained for a 30ft container for use as an
          allotment and community amenity and as a part time cafe.
        </p>

        <p>
          Mrs Maxcine Outten provided valuable evidence of local support but
          over the Spring 2024 period discussions with STC about a necessary
          minor amendment to the existing lease broke down. This will not stop
          efforts to offer a part time cafe but prevents a “trade or business”
          being set up This issue also prevented funding support being obtained
          from the Chesterfield Borough Council Community Fund but we were
          ultimately successful in obtained funding from Valencia Communities
          Fund for a £6000 Container (net of a “contributing charge of £835.30“)
          which was installed on June 5th 2024 .
        </p>

        <p>
          An advance of £1800 for legal fees had been paid relating to the lease
          costs but, following the breakdown of discussions £900 was recovered
          post the years end.
        </p>

        <p>
          <strong>John Hempshall Msc FCCA </strong>
        </p>
        <p>
          <strong>Treasurer June 10th 2024</strong>
        </p>
      </div>
      <hr />

      <div className="mt-3">
        <h2>TREASURERS REPORT</h2>
        <h3>REPORT AND ACCOUNTS FOR PERIOD ENDING MARCH 31ST 2023</h3>
        <p>
          Activity in 2022/2023 was relatively low thanks as we recovered from
          the Covid 19 pandemic.
        </p>

        <p>
          The year, nevertheless, was a successful one as we continued to
          progress the Chatsworth project with the Devonshire Property group
          (DPG) and signed up for a National Gardens Scheme weekend during
          2023.. Progress items with DPG included support for changes to the
          Bolsover Road access/hedge etc which are being resolved with Staveley
          Town Council and will improve access safety.
        </p>

        <p>
          The allotments remained fully let and the site continued to develop
          “naturally”.
        </p>

        <p>
          For the first year we did not receive any Grant income. We did place
          orders for a third new picnic bench from Bolsover Woodland Enterprise
          as well as a replacement “Monet bridge” Attempts to secure financial
          support were unsuccessful .
        </p>

        <p>
          Allotment fees were held increased to £30 for a full plot and a total
          of £395 was collected. A successful morning at Staveley market in
          August generated £61.36 but the only other income came from donations
          of £20 and bank interest of £22.80.
        </p>

        <p>
          Expenditure was held at a low level with no major cost issues. However
          new t shirts (£129.60) and the purchase of a generator (£442.80) were
          made in anticipation of the NGS weekend. Maintenance costs such as
          weedkiller (£83.94) and petrol (£158.02) plus high insurance (£142.50)
          contributed to a net excess of expenditure over income of £884.68.
        </p>

        <p>
          Further “forward “ work to support wider promotion of MMGA included a
          revamp of the website ...included in the IT costs of £91.44. and
          publicity posters costing £54 .
        </p>

        <p>
          The long running saga of our Water Supplies was finally resolved and
          although a charge of £59.43 was paid during the year a resolution of
          the meter readings resulted in a credit of £112 from Water Plus
          against future bills.
        </p>

        <p>
          The allotments and gardens continue to develop and mature and we
          continue to be indebted to the work of Ted, Ray and the “Working
          Party” volunteers in maintaining the site and to Joe Howes for the
          newly installed bee hives. Without this crucial work we could not
          maintain the site.
        </p>

        <p>
          It was noteworthy that the orchard produced a magnificent bounty of
          apples, plums, greengages quinces damsons and the first handful of
          Mulberries whilst the blackberry harvest was also superb.
        </p>

        <p>There were no changes to the Trustees during the year.</p>

        <p>
          <strong>John Hempshall Msc FCCA</strong>
        </p>

        <p>
          <strong>Treasurer April 23rd 2023</strong>
        </p>
      </div>
      <hr />

      <div className="mt-3">
        <h2>Chairmans Report for the year 2022/2023</h2>

        <p>
          2022 /2023 was another successful year of progress. The allotments
          continued to be fully utilised although sadly Mick Garvey, one of our
          original plotholders, suffered a stroke during the year and was unable
          to maintain his plot.
        </p>

        <p>
          The site is growing ever faster with trees, shrubs , orchards all
          reaching maturity and giving us a very pleasing view. Maintenance of
          the site is always hard work and I am grateful to the working party
          groups who turned out every few weeks to help keep on top of all the
          jobs ! Ray , Phil Glyn and several others appeared each week to
          support the key work of mowing strimming and weedkilling..
        </p>

        <p>
          We are hearing more and more very complimentary comments on our site
          and this encourages us to “keep going “.
        </p>

        <p>
          The Chatsworth development is reaching an interesting stage with
          “spades in the ground “ due late in 2023 and we continue our
          discussions on several key matters which will lead to us doubling the
          current 3 acre site.within a few years .
        </p>

        <p>
          No major events were held during the year as we all “came out” of the
          Covid epidemic . We have committed to holding an NGS weekend in July
          2023 and Graeme Challands is progressing this.
        </p>

        <p>
          We were excited to witness the first beehive on site , courtesy of Joe
          , and we look forward to seeing the first “produce” of their (the
          bees!)) labour .
        </p>

        <p>
          John has progressed the replacement Monet bridge with Bolsover
          Woodland Enterprise and this is due for installation in early summer
          in time for the NGS weekend .
        </p>

        <p>
          2023/2024 promises to be an exciting year and I look forward to
          continuing as Chairman
        </p>
        <p>
          <strong>Ted Chapman</strong>
        </p>
      </div>
      <hr />

      <div className="mt-3">
        <h2>TREASURERS REPORT 2022</h2>
        <p>TREASURERS REPORT</p>
        <p>
          Whilst activity in 2021/2022 was relatively low thanks to the ongoing
          Covid 19 pandemic the year was a successful one.
        </p>

        <p>
          The allotments remained fully let although there was a significant
          turnover with several new tenants, an indication in part of the
          effects of the lockdowns.
        </p>

        <p>
          We were grateful for several grants during the year. Councillors Lisa
          Collins and Paul Jacobs were instrumental in securing a £2000 grant
          from the Hartington Reclamation Scheme levy.. We were also able to
          secure £1800 from the Duke of Devonshire Charitable Trust which
          enabled us to improve the area around the compost site and provide
          some composting bins. We also obtained a second new bench from
          Bolsover Woodland Enterprise . Once again our own volunteer force
          installed this during the Spring of 2022 to replace the ageing benches
          previously installed.
        </p>

        <p>
          Late in the financial year we were provided with a further grant of
          £480 via the good offices of DCC Councillor Hayes which is being used
          to enable us to install a long sought after set of bee hives.
        </p>

        <p>
          It is ten years since we first started discussions with the Duke of
          Devonshire via his Chatsworth Settlement Trustees and more recently
          the Devonshire Property Group and its director Andrew Byrne. Events
          are finally moving forward as the Group commences the 650 housing
          development on the area to the south of the MMGA gardens . We have
          held progress meetings with the ultimate aim of adopting the
          additional three acres of land adjacent to the current site which will
          effectively double the MMGA site and give us room for further
          expansion and an increase in the number of allotments.
        </p>

        <p>
          Allotment fees were held at £25 for a full plot and a total of £414
          was collected as all the plots were let despite a significant
          “turnover “ of plotholders. It was encouraging to see a number of
          ladies taking up plots..
        </p>

        <p>
          Expenditure other than the fencing project was held at a low level
          with no major cost issues , resulting in an excess of income over
          expenditure of £1681.91. “Minor “ vandalism led to the provision of
          security cameras to monitor the key vulnerable buildings .
        </p>

        <p>
          The allotments and gardens continue to develop and mature and we
          continue to be indebted to the work of Ted, Ray and the “Working
          Party” volunteers in maintaining the site.{" "}
        </p>

        <p>
          Graeme Challands and Joe Howes have joined the Trustees and we look
          forward to their much needed help in continuing the development of
          |the MMGA gardens and allotments.{" "}
        </p>
        <p>John Hempshall Msc FCCA</p>
        <p>Treasurer May 3rd 2022</p>
      </div>
      <hr />
      <div>
        <h2>Chairmans report 2020</h2>
        <p>Chairman’s Report 2020</p>
        <p>
          It has been a pleasure to serve the association as Chairman for the
          last five years.Thank you for all your support and encouragement not
          to mention the friendships made.
        </p>
        <p>
          I judge the last year to be another one of progress and achievement
          all of which has only been possible through commitment and hard work
          by you the members. As a result the gardens and allotments is a place
          people can continue to find rest, enjoyment and peace especially at
          this difficult time.
        </p>
        <p>
          The memorable moments of this last year include the awful theft of
          equipment which has now thankfully been replaced. This happened just
          as we welcomed on site a group of about 15 young people who where
          completing their National Citizenship course.They used their energy
          enthusiasm and ingenuity to remodel our very tired sensory
          garden.Thanks go to Alison for heading this up.
        </p>
        <p>
          Our regular Working Parties fortified by hot drinks and goodies from
          Rita and Alison have restored the rose arbour , repaired a trellis
          near the Sensory garden , planted numerous trees and worked on other
          areas as time permitted.
        </p>
        <p>
          We have one project outstanding as I write and that is the renewal of
          our paths. Stage one should soon commence . We only wait on the result
          of two grant applications .Thank you John.
        </p>
        <p>
          As ever we are indebted to Ted and his helpers for all their work
          throughout the year which is invaluable. Ted is now working on making
          a labyrinth on the meadow area which incorporates the shape of a
          person and will include lots of wild flowers!
        </p>
        <p>
          We are sad to lose John Coulson as a trustee and an allotment holder
          but thank him for his invaluable help in recent years.
        </p>
        <p>
          We should have been having our AGM now but covid-19 has got in the
          way! We will be welcoming a new chairperson when we do convene and I
          wish that person well.
        </p>
        <p>
          In the meantime there are positives coming out of our unusual
          circumstances. We have more time to attend to our plots and the
          gardens. Every cloud has a ……..!
        </p>
        <p>Take care and stay Safe</p>
        <p>Keith Hodgson</p>
      </div>
      <hr />
      <div>
        <h2>Trustees MMGA Report 2017/18</h2>
        <p>Hi,</p>
        <p>
          A bit premature I know but an opportunity to wish you all a Merry Xmas
          and and Happy and prosperous New Year.Haveyou noticed we have changed
          our name? And established ourselves as a Charity registered with the
          Charities Commission ! (no. 1172232). And created a brand new website
          ….
          <a
            className="project-link"
            href="http://www.mastinmoorgardensandallotments.com/"
          >
            www.mastinmoorgardensandallotments.com
          </a>{" "}
          which brings our story up to date.
        </p>
        <p>
          Not very earth shattering maybe but relevant to our plans for our
          present site and our new site! Read on.
        </p>
        <p>
          For the present site we plan to install toilets , to upgrade some of
          the footpaths and to bring into use the cabin (previously toilets!)
          that has been on site for a year.These three things, in furthering our
          continued relationship with “Root and Branch” willmake a real
          difference to the site. We are grateful to Derbyshire County Council,
          Chesterfield Borough Council and Staveley Town Councilfor their
          support, Stavely having granted us a 25 yeas lease on our current
          site.
        </p>
        <p>
          For the new site, another circa 3 acres which will be adjacent the
          present one andleased long term to us by Chatsworth Trustees we
          propose a community building with car park. The building will include
          changing rooms, equipment storage.toilets, café and meeting /activity
          room. All available to the public as well as Allotment holders.All of
          course dependent on the Chatsworth housing plans being approved.
        </p>
        <p>
          This will only come about through much hard work and already some
          people are putting a fair shift.
        </p>
        <p>
          So we are going to need alot from our existing members who have grown
          in number.( Welcome guys) and from new friends. With this in mind we
          are inviting all who have an interest in MMGA but don’t want or have
          an allotment to become’Friends’ . So if you enjoy walking in the
          gardens, having a picnic there, walking your dog or just playing with
          your children , we would love you to sign up as a ‘Friend’ and get
          involved with what we are doing and have the satisfaction of helping
          to bring it about. We look forward to hearing from you (contacts
          details on our website)..
        </p>
        <p>
          Finally, and not least , my congratulations to the allotment holders
          who continue to improve their plots and grow their produce without the
          traditional sheds and accoutrements so that we can enjoy the openness
          of the site.Visitors always remark on what a wonderful location and
          vista we enjoy.!
        </p>
        <p></p>
        <p>Best wishes</p>
        <p>Keith Hodgson</p>
        <p>MMGA Chairman</p>
      </div>
      <hr />
      <div>
        <h2>Chairman’s report 2016</h2>
        <p>
          They say a week is a long time in politics but I can assure you a year
          is a long time as Chair of MMAA!
        </p>
        <p>
          As someone who vowed not to attend meetings once I retired I have done
          really well at not keeping my promise! More seriously though I have
          been on a learning curve and it has been stimulatinginteresting and
          challenging.
        </p>
        <p>
          We are grateful to allour members who have contributed to the upkeep
          of not only their plots but the gardens. We are particularly pleased
          to welcome new members and to feel the benefit of their contribution.
        </p>
        <p>
          We thank our secretary Lucy for her work as secretary and John as
          treasurer and Tedfor the invaluableinput from Root andBranch.
          Theintroduction of a community plot is a great concept and we hope
          that takes off. It could be a good way to introduce people to
          gardening and the idea of having an allotment.
        </p>
        <p>
          We struggled this summer to keep on top of the mowing what with the
          weather and thelack of experience some of ushad with the sit-on
          mowerwhile our lead mower was away!
        </p>
        <p>
          That challenge was nothingcompared to the challenge and opportunity
          that the proposed Chatsworth housing developmentis offering us. We are
          in discussions now with Chatsworth, CBC and STC regarding the
          enlargementof the present site by some 3 acres. A Steering committee
          has been set up and proposals are being drawn regarding to new site.
          So we have much to look forward to and much work to do before anything
          can happen,
        </p>
        <p>
          So lets go on working together. Each doing what we can to benefit the
          wider community and enhance the allotments and gardens. I think some
          of us willbe attending a few meetings but they come no where near to
          enjoyment that time on the plot brings.
        </p>
      </div>
      <hr />
      <div>
        <h2>Chairman’s report Monday 1st Dec 2014</h2>
        <p>
          So, unfortunately I have to start this report by apologising for being
          unable to attend the AGM for what has been a reasonably good year for
          MMAA. Thanks to John for continuing to do a good job with the
          accounts, the monthly newsletter and more. Thanks to Lucy for managing
          the plots, taking minutes and typing up the notes of all the meetings
          etc. Thanks also to Gerry for continuing to help out with the mowing
          and to everyone else who has helped maintain the gardens as part of
          the monthly volunteers group or otherwise.
        </p>
        <p>
          Turning Point is still closed, but we still have their sit on
          lawn-mower, Still hedge trimmer and attachments on loan until we hear
          from them, They Still have yet to be used but the mower has been a
          great help enabling Root And Branch volunteers and others to lighten
          the load for Gerry and keep the brambles back between the top gate and
          The Root And Branch container all year as well as keeping the central
          grassy area short for the second year running.
        </p>
        <p>
          Though still desperately in need of more volunteers the monthly work
          group has valiantly soldiered on with maintaining the flower beds and
          sensory garden etc. with Glyn continuing with spraying the paths to
          keep them weed free.
        </p>
        <p>
          On Sunday 20u April we gathered in the gardens to think of Ron as his
          memorial was put in place and a short eulogy given by Geoff.
        </p>
        <p>
          Our le anniversary day celebration was well attended and I am sure
          John will have the figures for funds raised on the day and with the
          April raffle etc.
        </p>
        <p>
          The year was rounded off with input from Steph Hoar (Youth Worker) and
          a group of teenagers from Mastin Moor. Steph managed to get 115 young
          tree saplings and the group got most of them planted around the site
          in October. They also made and donated a whole load of bird boxes and
          ‘insect homes’ which are in the container waiting to be put in place
          around the site. Unfortunately, a number of young trees in the
          arboretum area did not make it through the year and it is not clear
          what put paid to them.
        </p>
        <h4>
          Root And Branch Derbyshire mental health veer support and social
          horticulture group news:
        </h4>
        <p>
          The group has continued to meet on Wed and Thurs afternoons usually
          with 2-5 volunteers. As well as tending their own plot they have
          continued to help out other allotment holders as well as helping
          maintain the rest of the gardens. Work insulating and boarding out the
          shipping container is almost complete with just the dividing wall and
          workbench to sort. Closer links have been made with the NHS TRUST both
          with OT’s from the Hartington unit showing great interest in Root And
          Branch’s vision and beginning to make arrangements to refer people to
          the group and also visits to the site by TRUST management from Derby
          after Ted and Deb gave a presentation to the Trust Chair and Board of
          Governors. Ted is also having discussions with Leonards Cheshire Group
          who support people with learning difficulties looking how LCG may be
          involved with the gardens supporting a group to work alongside Root
          And Branch; it seems that many people with learning difficulties are
          beginning to develop mental health related problems as many of the
          activities/groups etc they used to attend have been shut down due to
          cut backs leaving individuals often socially isolated with little in
          the way of meaningful activities. Ted also managed to gain £9,000 Arts
          Council England Funding for a pilot Roadshow: Treating Mental Health
          Differently: plus, writing, recording and producing a related CD and
          booklet. As well as raising awareness on a range of mental health
          related issues the Roadshow also had info on what Root And Branch are
          doing at MMAA Gardens.
        </p>
        <p>
          Finally, a big thank you to Mastin Moor Miners Welfare for their
          hospitality and to all those that have helped in whatever way who I
          did not mention at the start, oh and to whoever has volunteered to
          read this out in my absence.
        </p>
      </div>
      <hr />
      <div>
        <h2>Chairman’s report 2013</h2>
        <p>
          The telephone rang, as it does; on picking it up, I heard our friend
          (Janet’s and mine) say, “I’ve got an allotment, would you help me with
          it?” “Er … Well … Yes” said I, in a mumbling sort of way. I was
          thinking on my feet, Janet is ‘the gardener, not me’; what might I do
          to help? Rita went onto explain that this was not just one allotment …
          it was the whole plot; over 3 acres of it !!!! And she wondered if I
          would take on the position of chairman and help her to create …. and
          she went onto outline this wonderful vision she had of turning this
          site into an oasis of peace and calm for the whole community. Well
          what could I do but say yes? In actual fact I said, “You are
          completely mad woman!” and then said “yes”.
        </p>
        <p>
          Needless to say, I was excited to see this plot …until I saw it! To
          say it was an eyesore is a huge understatement. It was absolutely
          derelict, run down, misused by drug addicts, underage drinkers etc,
          etc. There was rubbish everywhere, thistles nettles and brambles
          shoulder high; broken down huts. It was the very opposite of the
          picture Rita was painting.
        </p>
        <p>
          She told me she had applied to Staveley Town Council for an allotment
          and been told the allotment association had ceased to operate and
          whilst the Council would prefer the site to be run for allotments
          there was pressure on them to sell it. She heard herself saying at
          this moment, “well I will take on the whole site.” Who in their ‘right
          mind’ would say such a thing … and who in their right mind would
          volunteer to chair it???
        </p>
        <p>
          When Rita contacted the retired chairman of the old allotment society,
          she learned of the problems they had faced including, thefts,
          vandalism, drug taking, drinking etc that had eventually led to the
          society closing it’s doors. This in no way deterred Rita but rather
          spurred her on to create not a barred and exclusive site but an open
          and inclusive one where everyone was welcome and which was owned by
          the whole community and designed for the whole community.
        </p>
        <p>
          This was not long after the turn of the millennium, all those years
          ago. And as the old saying goes, a lot of water has passed under the
          bridge since then. I am proud to say, I stuck with my promise to help
          Rita, I am proud of what Rita achieved, I am proud of the enormous
          amount of help received from volunteers, Staveley Town Council,
          Staveley Neighbourhood Management, Local Authorities, and many other
          sources to create what is a wonderful Community garden with
          allotments, orchard, resting places, trees that are beginning to
          mature, beautiful flowers, plants and accessible to all, able and
          disabled, young and old, mums, dads, toddlers, dogs. Everyone is
          included, no one is excluded. And this has been demonstrated by
          opening the site to adults with learning disabilities, who not only
          had allotments but also created the sensory garden area. Currently, of
          course, Ted and his wonderful Root and Branch group are working on it
          and helping in so many new ways.
        </p>
        <p>
          Looking back over all the years as chairman, I no longer choose to
          remember the difficulties we faced but the opportunities and the
          wonderful people I met and who, like Rita and me, offered to help. I
          have been touched by the generosity of individuals, giving time,
          energy and money to create the oasis that Rita had held in her mind
          when the reality on the ground had been so very different
        </p>
        <p>
          We, Rita, I and the amazing group of people who volunteered to be on
          the committee had agreed that any help was welcomed. And it was always
          to be voluntary. If someone even picked up one piece of litter, cut
          just one piece of grass, dug over one small area of garden, it was
          welcomed. But so many people have made so much more effort and I have
          genuinely been overwhelmed by the contributions and generosity. I
          cannot thank those people enough. In the early years I wrote regular
          reports for the web site but other pressures meant this had to stop,
          my gratitude however certainly hasn’t.
        </p>
        <p>
          I am proud to have been associated, in my small way, with such a
          wonderful project. I am blessed and humbled to have known and learned
          from so many generous and loving people. Mastin Moor Community Gardens
          has strengthened my belief in people and the good they are capable of
          doing/being and I know that I leave the association in very capable
          hands. I know it will continue to grow, evolve and benefit the whole
          community. And I would just like to finish by once again saying thank
          you to everyone who has helped.
        </p>
        <p>Geoff Husband</p>
      </div>
      <hr />
      <div>
        <h2>Chairman’s report (Nov) 2011</h2>
        <p>Chairman’s Remarks</p>
        <p>
          Although it was only last March that we had our last (
          <em>delayed due to snow and germs)</em>AGM, it has been an eventful
          time:
        </p>
        <ul>
          <li>We have had monthly committee meetings and work parties.</li>
          <li>
            The Feasibility Study commissioned by Staveley Forward has been
            completed and shown a good level of support for the idea of a small
            café on the Community Garden.
          </li>
          <li>
            Building work on the Turning Point site goes on apace and we look
            forward to working with the charity in the future.
          </li>
          <li>
            We have been approached by a Staveley resident who is exploring the
            idea of setting up a ‘green gym’ and look forward to seeing how the
            idea develops.
          </li>
        </ul>
        <p>On the down side:</p>
        <ul>
          <li>
            Poor old Compost Corner fell victim to an arson attack and we lost
            quite a lot of useful items like chairs and tables.
          </li>
          <li>
            We’ve lost the water in our pond … well it has been the driest 12
            months for the Midlands on record, so, hopefully, we may yet see it
            replenished.
          </li>
          <li>
            Sadly, we have once again suffered from antisocial behaviour
            (including 2 arson attacks on the Garden itself) and can only say
            thank you to Sue Cooke and her colleagues from the Staveley Safer
            Neighbourhood Team for giving us such much appreciated support.
          </li>
        </ul>
        <p>
          Notwithstanding the ‘downs’, it has been good to see that the
          allotment plots have now all been taken and the Community Garden is
          still being well used by local people.Yet again, I have been very
          touched to see how much voluntary help is given to the Community
          Garden, so I want to thank:
        </p>
        <ul>
          <li>
            Ron, who has finally decided to ‘retire’ and hang up his hoe, for
            all his hard work on the Garden, for writing the newsletter and for
            always being so positive in the face of adversity!We won’t be
            missing him, as we expect him to continue to play his part at our
            committee meetings and to continue to share his many years of
            gardening experience and his common sense with us all.
            <em>
              I hear that he will still be out and about on Monday mornings,
              practising his supervisory skills
            </em>
            !
          </li>
        </ul>
        <ul>
          <li>
            I also want to acknowledge the hard work done by Glynn, who has
            supported Ron’s efforts in fair weather and foul.We really
            appreciate your work and commitment, Glynn.It is very good of you to
            give so much time to the Garden, when you are also working and
            caring for your sister, Julie.Thank you.
          </li>
          <li>
            Carol and Gerry have decided to take a break from the Committee; and
            I know that you will join me in thanking them for all their support
            and hard work since we started this venture.The Community Garden
            owes them a great debt, not least for cutting the grass year in,
            year out, but they have contributed in many other ways: in creating
            and maintaining the website at their own expense, undertaking the
            risk assessments, providing the barbeque on open days, donating to
            the upkeep of the site and of Compost Corner and to our raffles.The
            Community Garden could never have got off the ground, nor lasted
            this long, without Gerry and Carol’s support.They have told me that
            they will still be supporting our efforts and we hope to see them
            around the Garden on Work Days, if only for the lure of Rita’s buns!
          </li>
        </ul>
        <ul>
          <li>
            Thank you John, for once again doing our accounts and for all your
            labour on the Community Garden.Thanks too to Jill for joining the
            work parties,
          </li>
        </ul>
        <ul>
          <li>
            I also want to thank Lucy, who took over the job of managing the
            allotment plots and has done a fantastic job.
          </li>
        </ul>
        <ul>
          <li>
            Denise has yet again generously donated plants and helped keep an
            eye on things.Thank you for all your help.
          </li>
        </ul>
        <ul>
          <li>
            Thank you too to Fee Unwin from the PCT and PCSO Sue Cooke who have
            regularly turned out for our committee meetings and given us much
            appreciated advice and support.
          </li>
        </ul>
        <ul>
          <li>
            Thanks too to Rita for your hospitality, for the refreshments at the
            work parties, and for undertaking the role of Secretary.
          </li>
        </ul>
        <ul>
          <li>
            One final thank you to all of you who have kept on keeping on,
            making the allotments and Community Garden not only possible, but so
            successful.
          </li>
        </ul>
        <p>
          There is much to look forward to and I know that our hard work and
          persistence will, in the long run, prevail and that Mastin Moor
          Community Garden will continue to be an asset to the community, of
          which you can be justifiably proud.Thanks to everyone who has
          contributed to the fund raising hamper which will be raffled here next
          month, but we do need your continued help and support.Can you help us
          to sell some raffle tickets? Can you help with litter picking?Can you
          help with weeding or the wild life areas?Is there anything else you
          can do to help the Community Garden? As I always say, it is voluntary
          and every contribution, no matter what, is much appreciated.
        </p>
      </div>
      <hr />
      <div>
        <h2>Chairman’s report (March) 2011</h2>
        <p>
          Chairman’s Remarks<u></u>
        </p>
        <p>
          This will be the eighth year that we have welcomed people to the
          Community Garden and, although we have had occasional problems with
          anti-social behaviour, I am pleased to say that, despite those
          setbacks, it is a well used and well prized asset to the local
          community.
        </p>
        <p>
          I am very proud to have been able to serve as the Chair of such a
          determined, tenacious, loyal, and hardworking group.I often extol our
          virtues to people and cite Mastin Moor Community Garden as a fantastic
          example of what can be achieved by a team of dedicated volunteers.In
          fact, I recently told my tale to someone I was working with; and that
          same evening she began canvassing her neighbours to start a community
          garden themselves!
        </p>
        <p>
          Once again I can only say thank-you to all those who have made it
          possible for the Community Garden to continue to bring so much
          pleasure to so many people:
        </p>
        <p>
          Ron for his hard work each<em>(and almost every)</em>week (
          <em>
            he missed one or two due to some paltry excuse like 2 foot of snow
            and minus 10 degrees!)
          </em>
          .When he wasn’t<em>‘shirking’</em>he was picking up litter, emptying
          bins, weeding, planting, pruning, carrying out potato trials, writing
          and distributing newsletters … and generally idling his life away!Not
          bad for an 85 year old; in fact, not bad for someone half his age!
        </p>
        <p>
          And alongside Ron, more often than not, has been Glynn.I know Ron has
          really appreciated his support and help, as have we all.Although we
          may not have been able to say it to you in person as often as we would
          have liked Glynn, we do send you our thanks each week and I want
          people to know how much the Garden owes to both of you.
        </p>
        <p>
          I also want to thank Gerry and Carol for investing so much time,
          effort, and money in continuing to cut the grass on the site.And for
          maintaining the website, which is now splendidly adorned with their
          wildlife photos.They are very impressive
          <em>… as are the Latin names on the labels!</em>We are fortunate to
          have your support and look forward to seeing many more of your
          pictures.
        </p>
        <p>
          John, I want to say thank you for undertaking the role of
          Treasurer.You do a grand job.And thank you too for your help with
          maintaining the Garden, especially the pond, at our monthly work
          parties during the summer.
          <em>
            Clad in waders, mud and sweat you make a picture that could rival
            Gerry’s Red Admiral photo!
          </em>
          I am not sure what to say about the delivery of manure you so kindly
          arranged for while you were on holiday in Tenerife … we had a
          <em>wonderful</em>time getting it to the allotments!Still, I am sure
          your fellow plot-holders loved you for it!
        </p>
        <p>
          Thanks Philippa for typing and printing the monthly newsletter.Your
          support is greatly appreciated.
        </p>
        <p>
          Denise, once again you have quietly and generously supported the
          site.Thank you for your donations; they do make a difference.
        </p>
        <p>
          <em></em>Thank you to everyone who turned up to our committee meetings
          and thank you to Rita for hosting them, for providing the refreshments
          for our Saturday work parties, for taking care of the paperwork and
          all your hard work behind the scenes.
        </p>
        <p>
          Thank you to all those who have dug and planted and harvested their
          allotment plots through all the vagaries of a British summer.May the
          quality and quantity of your produce exceed your wildest dreams in
          2011.
        </p>
        <p>
          I am pleased to be able to tell you that Staveley Forward, a
          residents’ group, recently secured £7,000 for a feasibility study that
          will be looking at the idea we have for a café on the Garden and a
          cookery school for the area.It will also look into the use of the
          Healthy Living Centre by local people.If the feasibility study
          demonstrates that there is support for these ideas, they will then
          look to seek funding for a project worker to move things forward.
        </p>
        <p>
          Let’s look forward to 2011, to a year of sun,<em>but not too much</em>
          , of just the right amount of rain
          <em>and at just the right times,</em>and many more happy memories of
          Mastin Moor Community Garden for us, and for everyone who uses the
          site.
        </p>
        <p>Thank you for making it all possible.</p>
      </div>
      <hr />
      <div>
        <h2>Chairman’s report 2010</h2>
        <p>Chairman’s Remarks</p>
        <p>
          2009 was a year of mixed fortunes, both for the weather and for the
          Community Garden. We had more than our fair share of cool damp days
          and we also suffered from some anti-social behaviour, which led to us
          contacting the Safer Neighbourhood Team. We were not alone in
          experiencing such incidents; it seems that everywhere you go you hear
          and see evidence of anti-social behaviour, but we are not going to
          dwell on the negative. We are determined not to let the selfishness of
          the few spoil the lives of the many people who get great pleasure from
          our Community Garden and allotments.
        </p>
        <p>
          And good does come out of evil. Our contact with the Safer
          Neighbourhood Team led to us receiving help from PCSO Sue Cooke who
          helped to organise our Open Day in October. That proved to be a very
          enjoyable afternoon and, wonder of wonders, it actually stayed dry! In
          addition to Sue Cooke I want to thank Gerry and Carol Machen who once
          again did sterling work with their barbeque and tea-urn. I also want
          to thank John Hempshall for organising the Birds of Prey exhibition,
          which proved so popular. We were loaned some bouncy castles by the
          landlord of the Elm Tree in Staveley, and thank him too for his
          generosity. My thanks and appreciation also goes to the many people
          who worked so hard to make the day a success: Jill Hempshall and their
          extended family, Philippa Johnson, Rita Packwood, Mary White and the
          Tenants And Residents Association and to the Craft Group.
        </p>
        <p>
          But behind all that went on on the Open Day, was the huge effort made
          week in, week out by Ron Goodwin and Glynn Collins to keep the site
          clean and tidy and to maintain our many flower beds. And that is not
          all, Ron kindly writes our monthly newsletter, which Philippa is so
          good as to type and print out; and then Ron delivers or posts them! He
          is a star and has my utmost respect and admiration. Our thanks go to
          Mick Smith, who has generously repaired our water supply on more than
          one occasion. And yet again I must mention Gerry, who gives so
          generously of his time and money to mow the grass on the Community
          Garden throughout the year. I also want to mention Denise Evans who
          has donated many plants and helped us to bag some bargains from
          Dobbies Garden Centre, who also kindly gave us some gifts for our Open
          Day. Thanks also to those of you who attended the weekend working
          parties through the year. It is wonderful to know you and experience
          true generosity and community spirit.
        </p>
        <p>
          We have made some new friends for the Community Garden. Two people
          from the group Transition Town Chesterfield came along to the Open Day
          and were very impressed. If you have not already seen it, please visit
          their website and read what they wrote about our “gem” of a garden.
          They finished off by saying that they had seen a community that was
          alive and well in Mastin Moor and isn’t that what all our hard work is
          all about? But that isn’t the end of our fame … Chesterfield Borough
          Council is producing an Eco-Tour of the Borough booklet and our Mastin
          Moor Community Garden is the first entry, so we can look forward to
          welcoming many more visitors to the site. May the Community Gardens
          and allotments continue to go from strength to strength in 2010!
        </p>
        <p>
          I know that I have not been able to acknowledge every contribution to
          the Garden individually, but once again, I do want to say that we
          truly appreciate and value every gift of time, plants, and effort
          large and small that goes into making the Community Garden the great
          success that it is.
        </p>
        <p>Thanks everyone.</p>
      </div>
      <hr />
      <div>
        <h2>Chairman’s report 2007</h2>
        <p>
          This time last year I began my report by suggesting we pause and take
          stock on what had been achieved.
        </p>
        <ul>
          <li>We had a wonderful site for people to walk around and enjoy</li>
          <li>We had 16 Allotment plot holders</li>
          <li>
            The entrance way, centre circle and a third new flower/shrub bed had
            been glorious through the year
          </li>
          <li>
            Many people had walked around the site and made very favourable
            comments
          </li>
          <li>The garden had by then a permanent Water Supply</li>
          <li>and the Pond was progressing well</li>
          <li>We had a ‘gifted’ green house that was proving useful</li>
          <li>
            And the new sign at the entrance to the Community Garden was looking
            good.
          </li>
          <li>
            ‘Compost corner’ continued to survive thanks to running repairs and
            new heaters etc
          </li>
          <li>
            The Web Page was terrific and attracted very favourable comments
            from far and wide.
          </li>
          <li>Second Christmas raffle was in progress</li>
          <li>
            We had Networked with a number of organisations inc Barnsley
            Building Society; Presentation at SNM (national) conference, stand
            at CBC equality = respect event, etc
          </li>
          <li>
            And we had been successful (after previous failed attempts) with
            Funding Bids for the pond; sensory garden; as well as smaller bids
            for shrubs/plants for entrance area etc
          </li>
        </ul>
        <p>
          As I recall, we held the meeting at Ritas and had wine, beer, mince
          pies etc …
        </p>
        <p>but did we pause …. ?</p>
        <p>not a bit of it.</p>
        <p>This year even more has been achieved</p>
        <p>
          <strong>
            The Pond, the Bog garden and the Sensory garden together with its
            unique laburnum walk are by any measures a tremendous success
          </strong>
          :
        </p>
        <p>
          <strong>As is the generous community spirit</strong>, as recorded in
          the stand we erected for the CBC Equality = Respect / Unity in
          Community day held in November in Staveley: The willingness to work
          and help one another which has been a feature of this project since
          its inception, has continued throughout 2007.
        </p>
        <p>
          Yes there have been problems; the paths continue to challenge us, as
          does the wildflower meadow. Furthermore some one chose to use their
          talents not for constructive purposes but destructive ones and after a
          number of broken windows it was finally agreed to take down the green
          house: The stealing of the weed repellent material caused some
          distress also: And a few comments recorded on the web page were
          obviously forged in a sad mind: These however are but small set backs
          within a project that is proving to be an
          <strong>
            outstanding community success. And it is in the facing of such
            ‘problems’ and transforming them into opportunities that the
            Community Garden is growing.
          </strong>
        </p>
        <p>
          People have continued to help the
          <strong>learning disabilities</strong>group, as well as the
          <strong>‘young’ lads,</strong>with their plots: Not only are they
          continuing to learn but are feeling valued; … and it is in this light
          that a real community continues to grow as young and old work and
          learn together and
          <strong>
            <em>respect</em>
          </strong>
          one another! And let it not be forgotten that the young lads and
          especially the learning disability group contribute so much in their
          own way back to the site.
        </p>
        <p>
          Every one here present has been enormously generous helping in so many
          ways both financially and physically. In particular I would like to
          pay thanks to:
        </p>
        <p>
          Ron – our young at heart ‘80 something’; he is an inspiration giving
          help to both the gardens and other plot holders esp the group with
          learning disabilities and the even younger allotmenters. He keeps many
          areas of the site weed and litter free.
        </p>
        <p>
          Gerry &amp; Carol –website, general help in many other ways including
          the newly designed hi tec weed killer device
        </p>
        <p>John – treasurer, pond, etc</p>
        <p>
          Rita – secretarial work, paper work for bids etc (that so often can
          pass unnoticed but without which the project would grind to a halt),
          compost corner, keeping community payback team ‘happy’ with choc
          biscuits etc,
        </p>
        <p>
          Mick – for all the support to Ron, pond, bog garden, clearing the
          brambles etc
        </p>
        <p>
          Derek, Ian and Learning Disabilities group; flowers for front bed,
          green house(s), repairs/decoration/furniture in compost corner, water
          supply.
        </p>
        <p>
          Bolsover Woodland Enterprise continues to be helpful and have again
          donated wood chippings for the willow area and of course built the
          arch for the sensory gardens.
        </p>
        <p>
          But most of all I want to thank and remember Dec and Yvonne without
          whom the gardens simply would not have been what they are.
        </p>
        <p>
          Dec and Yvonne have been keen supporters of the Community Gardens
          since the very beginning. This is in itself no small achievement
          because they and other colleagues had experienced the problems which
          saw the demise of the old allotment association and everyone of the
          ‘old’ allotment holders had serious doubts as to whether it could be
          done. It was especially good to hear Dec say to Rita last year in his
          very own manly style, “
          <em>ey lass, I didn’t think it could be done but you’ve done it”.</em>
          Well Rita would be the first to say she hasn’t done it but everyone
          working together has done it, and none more so than Dec and Yvonne.
        </p>
        <p>
          Two years ago they even bought, a sit on mower and a shed to house it
          in, solely for use on the community gardens. Dec then undertook to
          keep the grass cut as well as maintain the mower all out of his and
          Yvonne’s own pockets!!! Yvonne has bought flowers/roses for the
          different flower beds and Dec has dug/maintained them. Dec almost
          single handedly dug out the ‘new’ site for the pond and bog garden and
          I remember the fun we had when he told John that he hadn’t filled in
          his site for the pond but merely temporarily put the soil on top! His
          choice of site (with Ron’s help) has been proved right however as it
          didn’t dry out even in the long dry spell experienced earlier this
          year.
        </p>
        <p>
          Between them Dec and Yvonne have been instrumental in bringing the
          gardens to the wonderful state that they are now in.
        </p>
        <p>
          And Dec was even travelling to represent Mastin Moor Community Gardens
          at Chesterfield in Bloom when the unexpected happened … Dec was very
          proud of the community gardens and rightly so And we are very proud of
        </p>
        <p>
          <strong>Dec</strong>
        </p>
        <p>Dec was a man’s man;</p>
        <p>worked in mines really rough,</p>
        <p>where life was hard</p>
        <p>and the going tough.</p>
        <p>Dec had a man’s voice</p>
        <p>chiselled by circumstance</p>
        <p>far underground</p>
        <p>amongst noise and violence</p>
        <p>Dec had a man’s strength</p>
        <p>forged at the coal face</p>
        <p>both pickaxe and spade</p>
        <p>he’d simply embrace</p>
        <p>But Dec also had heart</p>
        <p>and legacy has left</p>
        <p>in the community gardens</p>
        <p>where we are bereft,</p>
        <p>at the untimely passing</p>
        <p>of one who did give</p>
        <p>so much of himself</p>
        <p>that others might live,</p>
        <p>with this beautiful garden</p>
        <p>here at Mastin Moor:</p>
        <p>Your passing dear friend</p>
        <p>has left us more poor.</p>
        <p>But the garden lives on</p>
        <p>that others might share,</p>
        <p>what you helped to mould</p>
        <p>from land that was bare.</p>
        <p>Thank you from all</p>
        <p>who will enjoy without check</p>
        <p>this beautiful space</p>
        <p>you’ve helped create dear Dec.</p>
        <p>
          <strong>Chairman’s Report</strong>
        </p>
        <p>
          <strong>Good news</strong>
        </p>
        <p>
          ………. the old allotment site is once again open for gardeners. It is
          not only providing allotments however, it is also serving as a
          community garden for the benefit of everyone in and around Mastin
          Moor.
        </p>
        <p>
          <strong>Allotments</strong>
        </p>
        <p>
          For over 60 years the site served local residents, providing over 50
          allotment plots. However, by the year 2000, increasing vandalism
          forced the allotment group to disband. Since that time a dedicated
          group has worked to resurrect the site for the use of everyone and
          prevent it from being lost to the community.
        </p>
        <p>
          <strong>Community Garden</strong>
        </p>
        <p>
          Times change and our site is no longer simply about the provision of
          allotment plots. The site is being transformed into a garden that is
          here for the whole community to share and enjoy. Wheelchair and
          pram/buggy friendly paths have been laid allowing access around the
          garden to the many wildlife/bio-diverse features including a wild
          flower meadow, native British trees/woodland, a willow copse and
          community orchard.
        </p>
        <p>
          <strong>The Future</strong>
        </p>
        <p>
          Everything is still in its infancy, especially the young trees,
          replanted hedgerows and wildflower meadow, but this site is being
          designed with a view to the long-term. To this end, local residents
          both young and old (and those in-between!) will be able to enjoy and
          learn about wildlife, nature and the growing of fruit and vegetables
          as the gardens and trees change and develop through the seasons. Soon
          birds will begin visiting again and we can look forward to watching
          them nesting in the years ahead.
        </p>
        <p>
          Four seats have already been provided for your use and in the future
          we will be seeking funds to provide more and also raised/sensory beds
          especially for less able-bodied members. We also want to see the site
          provide opportunities for people to share their knowledge and
          experience and for others to learn, thereby enabling us all to live
          and eat more healthily. Examples of this will include sharing best
          practice in organic fruit and vegetable growing and in practical terms
          by the provision of community supported allotment growing plots for
          non-allotment holders including a communal polytunnel. We hope young
          people will become involved and perhaps even design and build their
          own willow structures. Another exciting development will be a scheme
          enabling local people to plant trees, shrubs and flowers to
          commemorate important anniversaries events and loved ones, both human
          and animal. Other developments might include a bog garden/pond area
          for wild life in a damper part of the site close to the willows. To
          help achieve these aims and as part of our philosophy of inclusion, we
          want Adults with Learning Disabilities to train and work on the site
          thereby enabling them to know and feel that they are making a positive
          contribution to society and the environment. Their skill levels and
          quality of life will be improved, thereby enabling them to enjoy more
          meaningful social relationships and enhance their feelings of self
          worth. To help achieve this aim, MMAA as a voluntary body is working
          in partnership with other voluntary as well as statutory bodies
          including: Staveley Town Council, Stavely Neighbourhood Management and
          Derbyshire County Council Social Services.
        </p>
        <p>
          <strong>Your Site</strong>
        </p>
        <p>
          This is truly your site. It is for everyone to use and enjoy. To this
          end please feel free to walk around the site. If you would like to
          take up an allotment, plant a commemorative tree/bush, need more
          information or perhaps would like to offer to help in any other way
          please contact one of the committee members.
        </p>
      </div>
    </div>
  );
}
