import React, { Component, useEffect, useState } from "react";
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { Form, Select, option } from 'react-bootstrap';
import newsData from "../news/news.json";

export default function News() {
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [count, setCount] = useState(0);
    const [pdfSelect, setPdfSelected] = useState();
    const [pdfArr, setPdfArr] = useState(newsData);

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
        setPageNumber(1);
    }

    function changePage(offset) {
        setPageNumber(prevPageNumber => prevPageNumber + offset);
    }

    function previousPage() {
        changePage(-1);
    }

    function nextPage() {
        changePage(1);
    }

    function handleSettingSelector(e) {
        setPdfSelected(e.target.value);
        setPageNumber(1);
    }

    function handlePdf () {
        if (pdfSelect){
            return (
                <div className="text-center">
                    <div className="page-controls">
                        <p>
                            Page {pageNumber || (numPages ? 1 : '--')} of {numPages || '--'}
                        </p>
                        <button
                            className="btn btn-success m-1"
                            type="button"
                            disabled={pageNumber <= 1}
                            onClick={previousPage}>
                            Previous
                        </button>
                        <button
                            className="btn btn-success m-1"
                            type="button"
                            disabled={pageNumber >= numPages}
                            onClick={nextPage}>
                            Next
                        </button>
                    </div>
                    <Document
                        file={`/news-pdf/${pdfSelect}.pdf`}
                        onLoadSuccess={onDocumentLoadSuccess}
                        renderMode="svg"
                        className={`pdf-document`}>
                        <Page pageNumber={pageNumber} />
                    </Document>
                </div>
            );  
        }
    }

    return (
        <div className="container mt-3 fixed-height news-container">
             <Form.Select
                  onChange={e => handleSettingSelector(e)}
                  className="browser-default custom-select mb-2">
                  <option value="zero">Please select a newsletter...</option>
                  {Object.keys(newsData).reverse().map(key => {
                        const select = newsData[key];
                        return (
                            <option key={key} value={select.url}>{select.name}</option>
                        )
                    })}
            </Form.Select>
            {handlePdf()}    
        </div>
    );
}