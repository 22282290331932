import React, { Component, useState } from "react";
import dataFile from '../data/projects_overview.json';
import { Outlet, useParams, useLocation } from "react-router-dom";


export default function ProjectExtended() {
const [data, setData] = useState(dataFile);
const { projectId } = useParams();

const values = data[projectId];

function handleProjectInformation (){
	if (values.additional_information.image_based_project === "No") {
		return (
			<>
	        <div className={`row`}>
				<h1>{values.additional_information.title}</h1>
				<h2 className={`h4`}>{values.additional_information.subtitle}</h2>
				{Object.keys(values.additional_information.body_text).map(key => {
					const bodyText = values.additional_information.body_text[key];
					return(
						<p key={key}>
							{bodyText}
						</p>
						);
				})}
				<h3 className={`h4`}>Project Images:</h3>
				{Object.keys(values.additional_information.images).map(key => {
					const additionalImages = values.additional_information.images[key];
					const imageDir = values.additional_information.image_dir;
					return(
						<div key={key} className={`col-sm-12 col-md-4 col-lg-3`}>
							<img className={`img-fluid`} src={`${imageDir}` + `${additionalImages}`} alt={key}/>
						</div>
						);
				})}
	        </div>
        </>
    );
	} else {
		return (
		        <>
		        <h1>{values.additional_information.title}</h1>
		        {Object.keys(values.additional_information.images).map(key => {
		        	const objectVal = values.additional_information.images[key];
					const imageDir = values.additional_information.image_dir;

					return (
						<div className={`row`} key={key}>
			        	<div className={`col col-sm-12 col-md-12 col-lg-4`}>
								<img className={`img-fluid`} src={`${imageDir}` + `${objectVal}`} alt={key}/>
						</div>
						<div className={`col col-sm-12 col-md-12 col-lg-8`}>
						<p>{values.additional_information.image_text[key]}</p>
						</div>
                  <hr className={`mt-3`} />
			        	</div>
			        	);
			     })}
		        </>
		    );
	}
}

   return (
        <>
	       {handleProjectInformation()}
        </>
    );
}