import React from "react";
import Image from 'react-bootstrap/Image';
import HeaderImage from '../img/header-img.png';
import { Link } from "react-router-dom";

export default function HeaderImg() {
    var sectionStyle = {
        backgroundImage:  "url(" + HeaderImage + ")",
        height: "180px",
        display: "flex",
        alignItems: "center"
    };

    return (
        <div className={`header--image`}>
            <div style={ sectionStyle }>
                <div className={`container on-top`}>
                    <div className="text-white container position-relative pt-3 row">
                        <div className={`col col-xs-8 col-sm-10`}>
                            <Link to="/" className="text-white text-decoration-none"><h1 className="h2">Mastin Moor Gardens and Allotments</h1></Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}