import React from "react";

export default function Membership() {
    return (
        <div className="container mt-3 fixed-height">
            <h1>Contact</h1>
            <div className="mt-3">
                <h2 className="h3">Chairman: Ted Chapman</h2>
                <p>Contact number: 07956488466</p>
                <p>E-mail: mastinmoorgardens2017@btinternet.com</p>
                <hr />
                <h2 className="h3">Vice Chairman: Joe Howes</h2>
                <p>Contact number: 07809384944</p>
                <p>E-mail: mastinmoorgardens2017@btinternet.com</p>
                <hr />
                <h2 className="h3">Secretary and Allotment Manager: Lucy Goucher</h2>
                <p>Contact number: 07522195861</p>
                <p>E-mail: mastinmoorgardens2017@btinternet.com</p>
                <hr />
                <h2 className="h3">Treasurer: John Hempshall</h2>
                <p>Contact number: 07919167574</p>
                <p>E-mail: mastinmoorgardens2017@btinternet.com</p>
            </div>
        </div>
    );
}